import { useState, useEffect } from "react";
import axios from "axios";
import EnhancedTable from "./table";
import './App.css';

function App() {
  const [getItems, setGetItems] = useState([]);

  useEffect(() => {
    async function fetchItems() {
      const URL = 'https://mlnh1l52lk.execute-api.us-east-1.amazonaws.com/v1/storage/items/';
      try {
        const res = await axios.get(URL);
        setGetItems(res.data);
      } catch (error) {
        console.log(error);
      }
    }
    fetchItems();
  }, []);

  if (!getItems.length) return <h3 style={{textAlign: "center"}}>Loading...</h3>;
  return (
    <div>
      <EnhancedTable items={getItems} />
    </div>
  );
}

export default App;
