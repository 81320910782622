import React from 'react';

const SearchBar = ({ input: keyword, onChange: setKeyword }) => {
  const BarStyling = { width: "20rem", background: "#F2F1F9", border: "none", padding: "0.5rem", display: "inline-block", float: "right", backgroundColor: "lightgray", margin: "0 auto" };

  return (
    <div style={{display: "flex", backgroundColor: "#FFC700", padding: "1em"}}>
      <input
        style={BarStyling}
        key="random1"
        value={keyword}
        placeholder={"Search"}
        onChange={(e) => setKeyword(e.target.value)}
      />
    </div>
  );
}

export default SearchBar;
